import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../components/Layout';
import BlogView from '../../components/BlogView';
import SectionIntro from '../../components/SectionIntro';
import Seo from '../../components/SEO';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {getImage} from 'gatsby-plugin-image';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            title
            author
            date(formatString: "MMMM DD, YYYY")
            tags
          }
          excerpt(pruneLength: 120, format: HTML)
        }
      }
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);

  const {backgroundImage} = data;
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  const posts = data.allMarkdownRemark.nodes;
  return (
    <Layout
      seo={
        <Seo
          title="Blog | Trustle"
          description="Thoughts and News from Trustle. Informative, Insightful, Inspirational."
        />
      }
    >
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext className="bg-top">
        <section className="overflow-hidden transform">
          <div className="relative inset-0 ">
            <div className="flex flex-col py-16 md:py-30 mx-5 md:mx-8 text-blue lg:max-w-[1920px] xl:mx-auto">
              <SectionIntro
                isPageHeader
                hero="The Blog"
                title="Thoughts and News from Trustle"
                description="Informative. Insightful. Inspirational."
              />

              <div className="mx-auto max-w-[85rem] grid grid-cols-1 md:grid-cols-2  gap-4">
                {posts.map((post, i) => (
                  <BlogView key={i} summary={true} post={post} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default BlogPage;
